export const storageTypeSelectOptions = [
    { label: 'Pallets', value: 'Pallets' },
    { label: 'Floor Space in sqm', value: 'Floor Space' },
]

export const storageType3PLSerchSelectOptions = [
    { label: 'Pallets', value: 'Pallets' },
    { label: 'Floor Space', value: 'Floor' },
    { label: 'Hardstand', value: 'Hardstand' },
]

export const storageTypeOptions = [
    { label: 'Pallets (racked)', value: 'Pallets' },
    { label: 'Floor (bulk or blockstack)', value: 'Floor' },
    { label: 'Hardstand', value: 'Hardstand' },
]

export const storageLengthOptions = [
    { label: '1 month', value: '1 month' },
    { label: '2 months', value: '2 months' },
    { label: '3 months', value: '3 months' },
    { label: '4 months', value: '4 months' },
    { label: '5 months', value: '5 months' },
    { label: '6 months', value: '6 months' },
    { label: '6 - 12 months', value: '6 - 12 months' },
    { label: '12 months +', value: '12 months +' },
]

export const leaseTermOptions = [
    { label: '> 12 months', value: '> 12 months' },
    { label: '1-3 years', value: '1-3 years' },
    { label: '3-5 years', value: '3-5 years' },
    { label: '5-7 years', value: '5-7 years' },
    { label: '7 years +', value: '7 years +' },
]

export const loadingDocksOptions = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '6+', value: '6+' },
]

export const yesNoOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]

export const filterOptions = [
    { label: 'All', value: 'All' },
    { label: 'Available', value: 'Available' },
    { label: 'Unavailable', value: 'Unavailable' },
]

export const accreditationsOptions = [
    'AQIS',
    'EPA',
    'Refrigerated',
    'Dairy',
    'Frozen',
    'Temp Controlled',
    'Dangerous goods',
    'HACCP approved',
    'TGA',
]

export const servicesOfferedOptions = [
    { label: 'International Freight', value: 'International Freight' },
    { label: 'Customs Brokerage', value: 'Customs Brokerage' },
    { label: 'Wharf Transport', value: 'Wharf Transport' },
    { label: 'Pick & Pack', value: 'Pick & Pack' },
    { label: 'Last Mile Fulfilment', value: 'Last Mile Fulfilment' },
    { label: 'Cross Dock', value: 'Cross Dock' },
    { label: 'Kitting', value: 'Kitting' },
    { label: 'Labelling', value: 'Labelling' },
    { label: 'Value added services', value: 'Value Added Services (VAS)' },
]

export const storageOfferingOption = [
    { label: 'Ambient', value: 'Ambient' },
    { label: 'Temp controlled', value: 'Temp controlled' },
    { label: 'Chilled', value: 'Chilled' },
    { label: 'Frozen', value: 'Frozen' },
    { label: 'Dairy', value: 'Dairy' },
    { label: 'HACCP', value: 'HACCP approved' },
    { label: 'Dangerous goods', value: 'Dangerous goods' },
    { label: 'TGA', value: 'TGA' },
    { label: 'Bonded', value: 'Bonded' },
    { label: 'Retail', value: 'Retail' },
    { label: 'E-Commerce', value: 'E-Commerce' },
    { label: 'Industrial', value: 'Industrial' },
    { label: 'AQIS', value: 'AQIS' },
]

export const minJobSizeOption = [
    { label: '1000 pallets', value: '1000+' },
    { label: '500 pallets', value: '500+ pallets' },
    { label: '100 pallets', value: '100+ pallets' },
    { label: '50 pallets', value: '50+ pallets' },
    { label: '1 pallet', value: '1+ pallets' },
]

export const maxJobSizeOption = [
    { label: '1,000+ pallets', value: '1,000 pallets +' },
    { label: '500 pallets', value: '500 pallets' },
    { label: '100 pallets', value: '100 pallets' },
    { label: '50 pallets', value: '50 pallets' },
    { label: '10 pallets', value: '10 pallets' },
]

export const countryOptions = [
    {
        label: 'Australia',
        value: 'AU',
        states: [
            { label: 'Australian Capital Territory', value: 'ACT' },
            { label: 'New South Wales', value: 'NSW' },
            { label: 'Northern Territory', value: 'NT' },
            { label: 'Queensland', value: 'QLD' },
            { label: 'South Australia', value: 'SA' },
            { label: 'Tasmania', value: 'TAS' },
            { label: 'Victoria', value: 'VIC' },
            { label: 'Western Australia', value: 'WA' },
        ],
    },
    {
        label: 'New Zealand',
        value: 'NZ',
        states: [
            { label: 'Auckland Region', value: 'AUK' },
            { label: 'Bay of Plenty Region', value: 'BOP' },
            { label: 'Canterbury Region', value: 'CAN' },
            { label: 'Chatham Islands', value: 'CIT' },
            { label: 'Gisborne District', value: 'GIS' },
            { label: "Hawke's Bay Region", value: 'HKB' },
            { label: 'Manawatu-Wanganui Region', value: 'MWT' },
            { label: 'Marlborough Region', value: 'MBH' },
            { label: 'Nelson Region', value: 'NSN' },
            { label: 'Northland Region', value: 'NTL' },
            { label: 'Otago Region', value: 'OTA' },
            { label: 'Southland Region', value: 'STL' },
            { label: 'Taranaki Region', value: 'TKI' },
            { label: 'Tasman District', value: 'TAS' },
            { label: 'Waikato Region', value: 'WKO' },
            { label: 'Wellington Region', value: 'WGN' },
            { label: 'West Coast Region', value: 'WTC' },
        ],
    },
]

export const userTypeOption = [
    { label: 'Supplier', value: 'supplier' },
    { label: 'Tenant', value: 'tenant' },
    { label: 'Landlord', value: 'landlord' },
]

export const partnerTiersOption = [
    { label: 'Tier 1', value: 'tier_1' },
    { label: 'Tier 2', value: 'tier_2' },
    { label: 'Tier 3', value: 'tier_3' },
    { label: 'Tier 4', value: 'tier_4' },
    { label: 'Blacklist', value: 'blacklist' },
]

export const serchTiersOption = [
    { label: 'Tier 1', value: 1 },
    { label: 'Tier 2', value: 2 },
    { label: 'Tier 3', value: 3 },
    { label: 'Tier 4', value: 4 },
    { label: 'Blacklist', value: 0 },
]
