import { createSlice } from '@reduxjs/toolkit'
import { themeConfig } from 'configs/theme.config'

const initialNavMode = () => {
    if (
        themeConfig.layout.type === 'modern' &&
        themeConfig.navMode !== 'themed'
    ) {
        return 'transparent'
    }

    return themeConfig.navMode
}

const initialState = {
    themeColor: themeConfig.themeColor,
    direction: themeConfig.direction,
    mode: themeConfig.mode,
    primaryColorLevel: themeConfig.primaryColorLevel,
    panelExpand: themeConfig.panelExpand,
    navMode: initialNavMode(),
    layout: themeConfig.layout,
}

const availableNavColorLayouts = ['classic', 'stackedSide', 'decked']

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setDirection: (state, { payload }) => {
            state.direction = payload
        },
        setMode: (state, { payload }) => {
            const availableColorNav = availableNavColorLayouts.includes(
                state.layout.type,
            )

            if (
                availableColorNav &&
                payload === 'dark' &&
                state.navMode !== 'themed'
            ) {
                state.navMode = 'dark'
            }
            if (
                availableColorNav &&
                payload === 'light' &&
                state.navMode !== 'themed'
            ) {
                state.navMode = 'light'
            }
            state.mode = payload
        },
        setLayout: (state, { payload }) => {
            state.cardBordered = payload === 'modern'
            if (payload === 'modern') {
                state.navMode = 'transparent'
            }

            const availableColorNav = availableNavColorLayouts.includes(payload)

            if (availableColorNav && state.mode === 'light') {
                state.navMode = 'light'
            }

            if (availableColorNav && state.mode === 'dark') {
                state.navMode = 'dark'
            }

            state.layout = {
                ...state.layout,
                ...{ type: payload },
            }
        },
        setPreviousLayout: (state, { payload }) => {
            state.layout.previousType = payload
        },
        setSideNavCollapse: (state, { payload }) => {
            state.layout = {
                ...state.layout,
                ...{ sideNavCollapse: payload },
            }
        },
        setNavMode: (state, { payload }) => {
            if (payload !== 'default') {
                state.navMode = payload
            } else {
                if (state.layout.type === 'modern') {
                    state.navMode = 'transparent'
                }

                const availableColorNav = availableNavColorLayouts.includes(
                    state.layout.type,
                )

                if (availableColorNav && state.mode === 'light') {
                    state.navMode = 'light'
                }

                if (availableColorNav && state.mode === 'dark') {
                    state.navMode = 'dark'
                }
            }
        },
        setPanelExpand: (state, { payload }) => {
            state.panelExpand = payload
        },
        setThemeColor: (state, { payload }) => {
            state.themeColor = payload
        },
        setThemeColorLevel: (state, { payload }) => {
            state.primaryColorLevel = payload
        },
    },
})

export const {
    setDirection,
    setMode,
    setLayout,
    setSideNavCollapse,
    setNavMode,
    setPanelExpand,
    setThemeColor,
    setThemeColorLevel,
    setPreviousLayout,
} = themeSlice.actions

export default themeSlice.reducer
