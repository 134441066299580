import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const DoubleSidedImage = ({ src, darkModeSrc, alt, ...rest }) => {
    const mode = useSelector((state) => state.theme.mode)

    return <img src={mode === 'dark' ? darkModeSrc : src} alt={alt} {...rest} />
}

DoubleSidedImage.propTypes = {
    darkModeSrc: PropTypes.string,
}

export default DoubleSidedImage
