const menu = {
    singleMenuItem: 'Single menu item',
    collapseMenu: {
        collapseMenu: 'Collapse menu iteme',
        item1: 'Collapse menu item 1',
        item2: 'Collapse menu item 2',
    },
    groupMenu: {
        groupMenu: 'Group menu',
        single: 'Group single menu item',
        collapse: {
            collapse: 'Group collapse menu',
            item1: 'Group collapse menu item 1',
            item2: 'Group collapse menu item 2',
        },
    },
}
export default menu
