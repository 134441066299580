export const themeConfig = {
    themeColor: 'red',
    direction: 'ltr',
    mode: 'light',
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: 'light',
    layout: {
        type: 'simple',
        sideNavCollapse: false,
    },
}
