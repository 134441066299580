import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    signedIn: false,
    uid: '',
    tokenType: '',
    accessToken: '',
    expiry: '',
    client: '',
}

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState,
    reducers: {
        onSignInSuccess: (state, { payload }) => {
            state.signedIn = true
            state.uid = payload.uid
            state.tokenType = payload.tokenType
            state.accessToken = payload.accessToken
            state.expiry = payload.expiry
            state.client = payload.client
        },
        onSignOutSuccess: () => initialState,
    },
})

export const { onSignInSuccess, onSignOutSuccess } = sessionSlice.actions

export default sessionSlice.reducer
