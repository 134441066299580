import { useState } from 'react'
import Select from '../Select'
import { FormItem } from '..'
import AddressSearch from './AddressSearch'
import {
    ErrorAddressDetails,
    fieldFeedback,
    fillInAddress,
} from './placesFunctions'
import classNames from 'classnames'
import CloseButton from '../CloseButton'
import { countryOptions } from 'utils/options'

const Locations = (props) => {
    const { name, value, heading, form, push, remove } = props
    const [country, setCountry] = useState('AU')

    const onChange = async (search) => {
        const address = await fillInAddress(search)
        push?.(address)
    }

    const removeSuburb = (e, index) => {
        e.preventDefault()
        remove?.(index)
    }

    return (
        <>
            <h6 className="mb-4">{heading}</h6>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <FormItem label="Country">
                    <Select
                        options={countryOptions}
                        value={countryOptions.filter(
                            (e) => e.value === country,
                        )}
                        onChange={(e) => setCountry(e.value)}
                    />
                </FormItem>
                <AddressSearch
                    errorMessage={fieldFeedback(form, name).errorMessage}
                    autocompletionRequest={{
                        types: ['locality', 'sublocality'],
                        componentRestrictions: { country },
                    }}
                    onChange={onChange}
                />
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-4">
                {value.map((suburb, index) => {
                    const alertClass = classNames(
                        'alert p-4 relative flex',
                        'bg-emerald-50 dark:bg-emerald-500',
                        'text-emerald-500 dark:text-emerald-50',
                        'font-semibold justify-between',
                        'items-center',
                        'rounded-lg',
                    )
                    return (
                        <div key={index}>
                            <div className={alertClass}>
                                <div className={`flex items-center`}>
                                    <div>{`${suburb.name}, ${suburb.state} ${suburb.post_code}`}</div>
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={(e) => removeSuburb(e, index)}
                                >
                                    <CloseButton defaultStyle={false} />
                                </div>
                            </div>
                            <ErrorAddressDetails
                                form={form}
                                name={`${name}[${index}]`}
                                fields={[
                                    'name',
                                    'state',
                                    'post_code',
                                    'country',
                                ]}
                            />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

Locations.defaultProps = {
    name: 'suburb',
    value: [],
    heading: '',
}

export default Locations
