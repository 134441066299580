import axios from 'axios'
import { apiError, authorization } from 'utils/apiError'
const { REACT_APP_URL } = process.env

const BaseService = axios.create({
    timeout: 60000,
    baseURL: REACT_APP_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})

BaseService.interceptors.request.use(
    (config) => authorization(config),
    (error) => {
        return Promise.reject(error)
    },
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        apiError(error)
        return Promise.reject(error)
    },
)

export default BaseService
