import BaseService from './BaseService'

const ApiService = {
    get(url, params) {
        return BaseService({
            method: 'GET',
            url: url,
            params,
        })
    },
    post(url, data) {
        return BaseService({
            method: 'POST',
            url: url,
            data: data ? JSON.stringify(data) : null,
        })
    },
    patch(url, data) {
        return BaseService({
            method: 'PATCH',
            url: url,
            data: data ? JSON.stringify(data) : null,
        })
    },
    update(url, slug, data) {
        return BaseService({
            method: 'PUT',
            url: url + '/' + slug,
            data: data ? JSON.stringify(data) : null,
        })
    },
    put(url, data) {
        return BaseService({
            method: 'PUT',
            url: url,
            data: data ? JSON.stringify(data) : null,
        })
    },
    delete(url, slug = '') {
        return BaseService({
            method: 'DELETE',
            url: url + (slug ? '/' + slug : ''),
        })
    },
}

export default ApiService
