import { forwardRef, useRef, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { FormItem, useConfig } from '..'
import classNames from 'classnames'
import { theme } from 'twin.macro'
import { HiOutlineSearch } from 'react-icons/hi'
const { REACT_APP_GOOGLE_PLACES_API } = process.env

const AddressSearch = forwardRef((props, ref) => {
    const {
        asterisk,
        errorMessage,
        autocompletionRequest,
        defaultValue,
        label,
        placeholder,
        clearValueOnSet,
        disabled,
        onChange,
    } = props
    const invalid = typeof errorMessage === 'string' && errorMessage
    const [value, setValue] = useState(defaultValue)
    const selectRef = useRef(null)

    const { themeColor, primaryColorLevel, mode } = useConfig()
    const inputFocusClass = `focus:ring-${themeColor}-${primaryColorLevel} focus-within:ring-${themeColor}-${primaryColorLevel} focus-within:border-${themeColor}-${primaryColorLevel} focus:border-${themeColor}-${primaryColorLevel}`
    const className = classNames(
        'input input-md h-11 p-0',
        !invalid && inputFocusClass,
        disabled && 'input-disabled',
        invalid && 'input-invalid',
    )

    const twColor = theme`colors`
    const selectStyles = {
        control: () => ({
            display: 'flex',
            cursor: 'pointer',
            padding: '4px',
        }),
        input: (provided) => ({
            ...provided,
            color: mode === 'dark' ? twColor.gray['400'] : twColor.gray['700'],
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor:
                mode === 'dark'
                    ? twColor.gray['700']
                    : provided.backgroundColor,
        }),
        option: (provided, state) => {
            let color =
                mode === 'dark' ? twColor.gray['400'] : twColor.gray['700']
            let backgroundColor = 'transparent'
            if (state.isFocused || state.isSelected) {
                backgroundColor =
                    mode === 'dark' ? twColor.gray['600'] : twColor.gray['50']
            }
            return {
                ...provided,
                color: color,
                backgroundColor: backgroundColor,
                '&:active': {
                    color: color,
                    backgroundColor: backgroundColor,
                },
            }
        },
    }
    return (
        <FormItem
            asterisk={asterisk}
            label={label}
            invalid={!!invalid}
            errorMessage={typeof errorMessage === 'string' && errorMessage}
        >
            <GooglePlacesAutocomplete
                apiKey={REACT_APP_GOOGLE_PLACES_API}
                selectProps={{
                    styles: {
                        ...selectStyles,
                    },
                    ref: ref ? ref : selectRef,
                    placeholder: placeholder,
                    value: value,
                    openMenuOnFocus: false,
                    openMenuOnClick: false,
                    isClearable: true,
                    isDisabled: disabled,
                    className,
                    components: {
                        DropdownIndicator: () => (
                            <div className="select-dropdown-indicator">
                                <HiOutlineSearch className="text-lg" />
                            </div>
                        ),
                    },
                    onChange: (search) => {
                        if (search) {
                            onChange?.(search)
                        }
                        setValue(clearValueOnSet ? null : search)
                        if (ref && ref.current) {
                            ref.current.blur()
                            ref.current.focus()
                        }
                        if (selectRef.current) {
                            selectRef.current.blur()
                            selectRef.current.focus()
                        }
                    },
                }}
                autocompletionRequest={{
                    componentRestrictions: { country: 'AU' },
                    ...autocompletionRequest,
                }}
            />
        </FormItem>
    )
})

AddressSearch.defaultProps = {
    asterisk: true,
    disabled: false,
    errorMessage: '',
    autocompletionRequest: {},
    defaultValue: '',
    label: 'Preferred suburbs',
    placeholder: 'Add a new location',
    clearValueOnSet: true,
}
export default AddressSearch
