import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setMode } from 'store/theme/themeSlice'

function useDarkMode() {
    const mode = useSelector((state) => state.theme.mode)

    const isEnabled = mode === 'dark'

    const dispatch = useDispatch()
    const onModeChange = (mode) => dispatch(setMode(mode))

    useEffect(() => {
        if (window === undefined) {
            return
        }
        const root = window.document.documentElement
        root.classList.remove(isEnabled ? 'light' : 'dark')
        root.classList.add(isEnabled ? 'dark' : 'light')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled])

    return [isEnabled, onModeChange]
}

export default useDarkMode
