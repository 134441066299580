import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en'
import fr from './lang/fr'
const { REACT_APP_LNG } = process.env

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
}

i18n.use(initReactI18next).init({
    debug: false,
    resources,
    lng: REACT_APP_LNG || 'en',
    fallbackLng: REACT_APP_LNG || 'en',
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    fr: () => import('dayjs/locale/fr'),
}

export const languageList = [
    { label: 'English', value: 'en', flag: 'us' },
    { label: 'French', value: 'fr', flag: 'fr' },
]

export default i18n
