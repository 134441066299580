import AddressSearch from './AddressSearch'
import {
    ErrorAddressDetails,
    fieldFeedback,
    retrieveAddressDetails,
} from './placesFunctions'
import classNames from 'classnames'
import CloseButton from '../CloseButton'

const LocationsSerch = (props) => {
    const { name, value, form, push, remove, autocompletionRequest } = props

    const onChange = async (search) => {
        const address = await retrieveAddressDetails(search)
        push?.(address)
    }

    const removeSuburb = (e, index) => {
        e.preventDefault()
        remove?.(index)
    }

    return (
        <div className="grid grid-cols-1 gap-1 mb-2">
            <AddressSearch
                asterisk={false}
                label={false}
                errorMessage={fieldFeedback(form, name).errorMessage}
                autocompletionRequest={autocompletionRequest}
                onChange={onChange}
            />
            {value.map((address, index) => {
                const alertClass = classNames(
                    'alert p-4 relative flex',
                    'bg-emerald-50 dark:bg-emerald-500',
                    'text-emerald-500 dark:text-emerald-50',
                    'font-semibold justify-between',
                    'items-center',
                    'rounded-lg',
                )
                return (
                    <div key={index}>
                        <div className={alertClass}>
                            <div className={`flex items-center`}>
                                <div>{`${address.street_address_1} ${address.suburb}, ${address.state} ${address.postcode}`}</div>
                            </div>
                            <div
                                className="cursor-pointer"
                                onClick={(e) => removeSuburb(e, index)}
                            >
                                <CloseButton defaultStyle={false} />
                            </div>
                        </div>
                        <ErrorAddressDetails
                            form={form}
                            name={`${name}[${index}]`}
                            fields={[
                                'street_address_1',
                                'street_address_2',
                                'suburb',
                                'state',
                                'postcode',
                                'country',
                                'lat',
                                'lng',
                            ]}
                        />
                    </div>
                )
            })}
        </div>
    )
}

LocationsSerch.defaultProps = {
    name: 'suburb',
    value: [],
    autocompletionRequest: {},
}

export default LocationsSerch
