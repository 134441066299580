import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentLang: 'en',
}

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLang: (state, { payload }) => {
            state.currentLang = payload
        },
    },
})

export const { setLang } = localeSlice.actions

export default localeSlice.reducer
