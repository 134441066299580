import { onSignOutSuccess } from 'store/auth/sessionSlice'
import { toast } from 'components/ui'
import store from 'store'
import { deepParseJson } from './deepParseJson'

export function authorization(config) {
    const rawPersistData = localStorage.getItem('admin')
    const persistData = deepParseJson(rawPersistData)

    let tokenData = persistData.auth.session

    if (!tokenData.signedIn) {
        const { auth } = store.getState()
        tokenData = auth.session
    }

    if (tokenData.signedIn) {
        config.headers['access-token'] = tokenData.accessToken
        config.headers['token-type'] = tokenData.tokenType
        config.headers['client'] = tokenData.client
        config.headers['expiry'] = tokenData.expiry
        config.headers['uid'] = tokenData.uid
    }

    return config
}
export function apiError(error) {
    let text = null
    const { response, message } = error
    if (response) {
        const { status, data, message } = response
        if (status === 401) {
            store.dispatch(onSignOutSuccess())
        } else if (status === 422) {
            if (typeof data.errors === 'string') {
                text = data.errors
            } else if (typeof data.errors === 'object') {
                for (const key in data.errors) {
                    if (typeof data.errors[key] === 'string') {
                        toast.error(data.errors[key])
                    } else {
                        for (const keys in data.errors[key]) {
                            toast.error(key + ' ' + data.errors[key][keys])
                        }
                    }
                }
            }
        } else if (status === 404) {
            text = '404 Not Found'
        } else if (status === 405) {
            text = '405 Method Not Allowed'
        } else if (status === 500) {
            text = '500 Internal Server Error'
        } else if (data && data.errors && Array.isArray(data.errors)) {
            data.errors.map((e) => {
                toast.error(e)
                return e
            })
        } else if (message) text = message
    } else if (message) text = message

    if (text) toast.error(text)
}
