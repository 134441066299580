import React, { useEffect, useState } from 'react'
import { HiOutlineDownload } from 'react-icons/hi'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children } = props
    const { url, type, name, size } = file

    const [fileData, setFileData] = useState({ url, type, name, size })
    const renderThumbnail = () => {
        const isImageFile = fileData.type
            ? fileData.type.split('/')[0] === 'image'
            : false
        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={
                        fileData.url ? fileData.url : URL.createObjectURL(file)
                    }
                    alt={`file preview ${fileData.name}`}
                />
            )
        }

        if (fileData.type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (fileData.type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    const getData = async () => {
        if (url) {
            const response = await fetch(url)
            const blob = await response.blob()
            setFileData((e) => ({ ...e, size: blob.size, type: blob.type }))
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="upload-file">
            <div className="flex">
                <div className="upload-file-thumbnail">{renderThumbnail()}</div>
                <div className="upload-file-info">
                    <h6 className="upload-file-name">{fileData.name}</h6>
                    <span className="upload-file-size">
                        {getKB(fileData.size ?? 0)} kb
                    </span>
                </div>
            </div>
            <div className="flex">
                {url ? (
                    <a
                        href={fileData.url}
                        className={'close-btn upload-file-remove'}
                        role="button"
                        download={fileData.name}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <HiOutlineDownload className="text-lg" />
                    </a>
                ) : (
                    ''
                )}
                {children}
            </div>
        </div>
    )
}

export default FileItem
