const menu = {
    singleMenuItem: 'Élément de menu unique',
    collapseMenu: {
        collapseMenu: 'Élément de menu Réduire',
        item1: "Réduire l'élément 1 du menu",
        item2: 'Élément 2 du menu Réduire',
    },
    groupMenu: {
        groupMenu: 'Menu de groupe',
        single: 'Élément de menu unique de groupe',
        collapse: {
            collapse: 'Menu réduit du groupe',
            item1: 'Élément de menu Réduire le groupe 1',
            item2: 'Élément 2 du menu Réduire le groupe',
        },
    },
}
export default menu
