import { getIn } from 'formik'
import {
    geocodeByAddress,
    geocodeByLatLng,
    geocodeByPlaceId,
} from 'react-google-places-autocomplete'

export async function fillInAddress(location) {
    let result = null
    if (location?.value?.place_id) {
        const place = await geocodeByPlaceId(location.value.place_id)
        if (place.length) {
            result = place[0]
        }
    }

    let name = ''
    let state = ''
    let post_code = ''
    let country = ''

    if (result?.address_components) {
        result.address_components
            .slice()
            .reverse()
            .forEach((component) => {
                if (component.types.includes('sublocality')) {
                    name = component.long_name
                }
                if (component.types.includes('locality')) {
                    name = component.long_name
                }
                if (component.types.includes('administrative_area_level_2')) {
                    name = component.long_name
                }
                if (component.types.includes('administrative_area_level_1')) {
                    state = component.short_name
                }
                if (component.types.includes('postal_code')) {
                    post_code = component.short_name
                }
                if (component.types.includes('country')) {
                    country = component.short_name
                }
            })
        if (!post_code) {
            const places = await geocodeByLatLng({
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng(),
            })
            if (places.length) {
                places.some((place) => {
                    for (let i = 0; i < place.address_components.length; i++) {
                        const component = place.address_components[i]
                        if (component.types.includes('postal_code')) {
                            post_code = component.short_name
                            return true
                        }
                    }
                    return false
                })
            }
        }
    }
    return {
        name,
        state,
        post_code,
        country,
    }
}

export async function retrieveAddressDetails(location) {
    let result = null
    if (location?.value?.place_id) {
        const place = await geocodeByPlaceId(location.value.place_id)
        if (place.length) {
            result = place[0]
        }
    }

    let subpremise = ''
    let street_number = ''
    let street_name = ''
    let street_address_2 = ''
    let suburb = ''
    let state = ''
    let postcode = ''
    let country = ''

    if (result?.address_components) {
        result.address_components
            .slice()
            .reverse()
            .forEach((component) => {
                if (component.types.includes('subpremise')) {
                    subpremise = component.long_name
                }
                if (component.types.includes('street_number')) {
                    street_number = component.long_name
                }
                if (component.types.includes('route')) {
                    street_name = component.long_name
                }
                if (component.types.includes('subpremise')) {
                    street_address_2 = component.long_name
                }
                if (component.types.includes('locality')) {
                    suburb = component.long_name
                }
                if (component.types.includes('administrative_area_level_1')) {
                    state = getNZIsland(component.short_name)
                }
                if (component.types.includes('postal_code')) {
                    postcode = component.long_name
                }
                if (component.types.includes('country')) {
                    country = component.short_name
                }
            })
    }
    return {
        street_address_1: `${
            subpremise ? subpremise + '/' : ''
        }${street_number} ${street_name}`,
        street_address_2,
        suburb,
        state,
        postcode,
        country,
        lat: result?.geometry?.location?.lat() ?? 0,
        lng: result?.geometry?.location?.lng() ?? 0,
    }
}

const getNZIsland = (region) => {
    const northIslandRegions = [
        'Auckland',
        'Bay of Plenty',
        'Gisborne',
        "Hawke's Bay",
        'Manawatu-Whanganui',
        'Northland',
        'Taranaki',
        'Waikato',
        'Wellington',
    ]

    const southIslandRegions = [
        'Canterbury',
        'Marlborough',
        'Nelson',
        'Otago',
        'Southland',
        'Tasman',
        'West Coast',
    ]

    if (northIslandRegions.includes(region)) return 'North Island'
    if (southIslandRegions.includes(region)) return 'South Island'
    return region
}

export const fieldFeedback = (form, name) => {
    const error = getIn(form.errors, name)
    const touch = getIn(form.touched, name)
    return {
        errorMessage: error || '',
        invalid: typeof touch === 'undefined' ? false : error && touch,
    }
}

export const ErrorAddressDetails = ({ form, name, fields }) => {
    return (
        <div className="flex flex-col text-red-500">
            {fields.map((field) => {
                const felds = fieldFeedback(form, `${name}.${field}`)
                return felds.invalid && <p>{felds.errorMessage}</p>
            })}
        </div>
    )
}

export async function getLatLng(address) {
    let result = null
    const place = await geocodeByAddress(address)
    if (place && place.length >= 1) {
        result = place[0]
    }
    return {
        lat: result?.geometry?.location?.lat() ?? 0,
        lng: result?.geometry?.location?.lng() ?? 0,
    }
}
