import { useState } from 'react'
import Select from '../Select'
import { Button, Checkbox, DatePicker, FormItem, Input, toast } from '..'
import AddressSearch from './AddressSearch'
import {
    ErrorAddressDetails,
    fieldFeedback,
    getLatLng,
    retrieveAddressDetails,
} from './placesFunctions'
import classNames from 'classnames'
import CloseButton from '../CloseButton'
import { Field, Formik } from 'formik'
import { HiCake } from 'react-icons/hi'
import * as Yup from 'yup'
import { countryOptions } from 'utils/options'

const validationSchema = Yup.object().shape({
    street_address_1: Yup.string().required('Street is required'),
    suburb: Yup.string().required('Suburb is required'),
    state: Yup.string().required('State is required'),
    postcode: Yup.number()
        .typeError('Please enter a numerical value')
        .required('Postcode is required'),
    country: Yup.string().required('Country is required'),
})

const AddressDetail = (props) => {
    const {
        name,
        asterisk,
        manuallyAdd,
        value,
        heading,
        infoText,
        form,
        push,
        remove,
        extraField,
        gridColls,
    } = props
    const [manual, setManual] = useState(false)

    const onChange = async (search) => {
        const address = await retrieveAddressDetails(search)
        push?.({ ...address, ...extraField })
    }

    const removeAddress = (e, index) => {
        e.preventDefault()
        remove?.(index)
    }

    return (
        <>
            {heading && <h6 className="mb-4">{heading}</h6>}
            {infoText && <p className="mb-4">{infoText}</p>}
            <Formik
                initialValues={{
                    street_address_1: '',
                    suburb: '',
                    state: '',
                    postcode: '',
                    country: 'AU',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        const { lat, lng } = await getLatLng(
                            `${values.street_address_1}, ${values.suburb}, ${values.state}, ${values.country}`,
                        )
                        values.street_address_2 = ''
                        values.lat = lat
                        values.lng = lng
                        push?.({ ...values, ...extraField })
                        resetForm()
                    } catch (err) {
                        toast.error('Invalid address')
                    }
                }}
            >
                {({ values, touched, errors, isSubmitting, handleSubmit }) => (
                    <div
                        className={`grid grid-cols-1 gap-x-4 md:grid-cols-${
                            manual ? '4' : '2'
                        }`}
                    >
                        <FormItem
                            label="Country"
                            invalid={errors.country && touched.country}
                            errorMessage={errors.country}
                            className={`${manual ? 'md:col-span-2' : ''}`}
                        >
                            <Field name="country">
                                {({ field, form: form2 }) => (
                                    <Select
                                        field={field}
                                        form={form2}
                                        options={countryOptions}
                                        value={countryOptions.filter(
                                            (e) => e.value === values.country,
                                        )}
                                        onChange={(e) => {
                                            form2.setFieldValue(
                                                field.name,
                                                e.value,
                                            )
                                            form2.setFieldValue('state', '')
                                        }}
                                    />
                                )}
                            </Field>
                        </FormItem>
                        {!manual ? (
                            <AddressSearch
                                asterisk={asterisk}
                                errorMessage={
                                    fieldFeedback(form, name).errorMessage
                                }
                                autocompletionRequest={{
                                    types: ['address'],
                                    componentRestrictions: {
                                        country: values.country,
                                    },
                                }}
                                onChange={onChange}
                            />
                        ) : (
                            <>
                                <FormItem
                                    asterisk
                                    label="Street Address"
                                    invalid={
                                        errors.street_address_1 &&
                                        touched.street_address_1
                                    }
                                    errorMessage={errors.street_address_1}
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name="street_address_1"
                                        placeholder="Street Address"
                                        component={Input}
                                    />
                                </FormItem>
                                <FormItem
                                    asterisk
                                    label="Suburb"
                                    invalid={errors.suburb && touched.suburb}
                                    errorMessage={errors.suburb}
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name="suburb"
                                        placeholder="Suburb"
                                        component={Input}
                                    />
                                </FormItem>
                                <FormItem
                                    asterisk
                                    label="State"
                                    invalid={errors.state && touched.state}
                                    errorMessage={errors.state}
                                >
                                    <Field name="state">
                                        {({ field, form: form2 }) => {
                                            let option = countryOptions.find(
                                                (e) =>
                                                    e.value === values.country,
                                            )
                                            return (
                                                <Select
                                                    field={field}
                                                    form={form2}
                                                    options={
                                                        option
                                                            ? option.states
                                                            : []
                                                    }
                                                    value={(option
                                                        ? option.states
                                                        : []
                                                    ).filter(
                                                        (e) =>
                                                            e.value ===
                                                            values.state,
                                                    )}
                                                    onChange={(e) =>
                                                        form2.setFieldValue(
                                                            field.name,
                                                            e.value,
                                                        )
                                                    }
                                                />
                                            )
                                        }}
                                    </Field>
                                </FormItem>
                                <FormItem
                                    asterisk
                                    label="Postcode"
                                    invalid={
                                        errors.postcode && touched.postcode
                                    }
                                    errorMessage={errors.postcode}
                                    className="md:col-span-2"
                                >
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                        <Field
                                            type="text"
                                            autoComplete="off"
                                            name="postcode"
                                            placeholder="Postcode"
                                            component={Input}
                                        />
                                        <Button
                                            variant="solid"
                                            type="button"
                                            loading={isSubmitting}
                                            onClick={handleSubmit}
                                        >
                                            Save Address
                                        </Button>
                                    </div>
                                </FormItem>
                            </>
                        )}
                    </div>
                )}
            </Formik>
            {manuallyAdd && (
                <p
                    className="cursor-pointer"
                    onClick={() => setManual((e) => !e)}
                >
                    {manual
                        ? 'To switch back to address search, click here'
                        : 'Address not showing up? Click here to enter manually'}
                </p>
            )}
            {value.map((address, index) => {
                const alertClass = classNames(
                    'alert p-4 relative flex',
                    'bg-emerald-50 dark:bg-emerald-500',
                    'text-emerald-500 dark:text-emerald-50',
                    'font-semibold justify-between',
                    'items-center',
                    'rounded-lg',
                )
                return (
                    <div
                        key={index}
                        className={`grid grid-cols-1 gap-x-4 ${gridColls}`}
                    >
                        <div className="mb-4 md:mt-4 md:col-span-2">
                            <div className={alertClass}>
                                <div className={`flex items-center`}>
                                    <div>{`${address.street_address_1} ${address.suburb}, ${address.state} ${address.postcode}`}</div>
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={(e) => removeAddress(e, index)}
                                >
                                    <CloseButton defaultStyle={false} />
                                </div>
                            </div>
                            {'managed_by_someone_else' in extraField && (
                                <Field
                                    className="mt-2"
                                    name={`${name}[${index}].managed_by_someone_else`}
                                    component={Checkbox}
                                    children="This facility is run by someone else on our behaif"
                                />
                            )}
                            <ErrorAddressDetails
                                form={form}
                                name={`${name}[${index}]`}
                                fields={[
                                    'street_address_1',
                                    'street_address_2',
                                    'suburb',
                                    'state',
                                    'postcode',
                                    'country',
                                    'lat',
                                    'lng',
                                ]}
                            />
                        </div>
                        {'storageSize' in extraField && (
                            <div>
                                <FormItem
                                    asterisk
                                    label="Warehouse size (sqm)"
                                    invalid={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].storageSize`,
                                        ).invalid
                                    }
                                    errorMessage={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].storageSize`,
                                        ).errorMessage
                                    }
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name={`${name}[${index}].storageSize`}
                                        placeholder="Warehouse size (sqm)"
                                        component={Input}
                                    />
                                </FormItem>
                            </div>
                        )}
                        {'currentSpace' in extraField && (
                            <div>
                                <FormItem
                                    label="Current space available"
                                    invalid={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].currentSpace`,
                                        ).invalid
                                    }
                                    errorMessage={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].currentSpace`,
                                        ).errorMessage
                                    }
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name={`${name}[${index}].currentSpace`}
                                        placeholder="Current space available"
                                        component={Input}
                                    />
                                </FormItem>
                            </div>
                        )}

                        {'warehouse_size' in extraField && (
                            <div>
                                <FormItem
                                    asterisk
                                    label="Warehouse size (sqm)"
                                    invalid={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].warehouse_size`,
                                        ).invalid
                                    }
                                    errorMessage={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].warehouse_size`,
                                        ).errorMessage
                                    }
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name={`${name}[${index}].warehouse_size`}
                                        placeholder="Warehouse size (sqm)"
                                        component={Input}
                                    />
                                </FormItem>
                            </div>
                        )}
                        {'current_space_available' in extraField && (
                            <div>
                                <FormItem
                                    label="Current space available"
                                    invalid={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].current_space_available`,
                                        ).invalid
                                    }
                                    errorMessage={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].current_space_available`,
                                        ).errorMessage
                                    }
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name={`${name}[${index}].current_space_available`}
                                        placeholder="Current space available"
                                        component={Input}
                                    />
                                </FormItem>
                            </div>
                        )}
                        {'lease_renewal' in extraField && (
                            <div>
                                <FormItem
                                    label="Lease Expiry Date"
                                    invalid={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].lease_renewal`,
                                        ).invalid
                                    }
                                    errorMessage={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].lease_renewal`,
                                        ).errorMessage
                                    }
                                >
                                    <Field
                                        name={`${name}[${index}].lease_renewal`}
                                    >
                                        {({ field, form }) => (
                                            <DatePicker
                                                field={field}
                                                form={form}
                                                value={field.value}
                                                prefix={
                                                    <HiCake className="text-xl" />
                                                }
                                                placeholder="Lease Expiry Date"
                                                onChange={(date) =>
                                                    form.setFieldValue(
                                                        field.name,
                                                        date,
                                                    )
                                                }
                                            />
                                        )}
                                    </Field>
                                </FormItem>
                            </div>
                        )}
                        {'landlord' in extraField && (
                            <div>
                                <FormItem
                                    label="Landlord"
                                    invalid={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].landlord`,
                                        ).invalid
                                    }
                                    errorMessage={
                                        fieldFeedback(
                                            form,
                                            `${name}[${index}].landlord`,
                                        ).errorMessage
                                    }
                                >
                                    <Field
                                        type="text"
                                        autoComplete="off"
                                        name={`${name}[${index}].landlord`}
                                        placeholder="Landlord"
                                        component={Input}
                                    />
                                </FormItem>
                            </div>
                        )}
                    </div>
                )
            })}
        </>
    )
}

AddressDetail.defaultProps = {
    name: 'locations',
    asterisk: false,
    manuallyAdd: false,
    value: [],
    heading: '',
    infoText: '',
    extraField: {
        warehouse_size: '',
        current_space_available: '',
        lease_renewal: '',
        landlord: '',
    },
    gridColls: 'md:grid-cols-6',
}

export default AddressDetail
